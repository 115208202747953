import {
  getDataOrAttr,
} from "../../js_dzsvg/_dzsvg_helpers";
import {sanitizeToCssPx} from "../../js_common/_dzs_helpers";


export function dzsvg_mode_wall_init(selfClass) {
  const o = selfClass.initOptions;


  o.menu_position = 'all';

  if(o.navigation_gridClassItemsContainer==='default'){
    o.navigation_gridClassItemsContainer = 'dzs-layout--3-cols';
  }
}
export function dzsvg_mode_wall_reinitWallStructure(selfClass) {
  // -- wall

  const o = selfClass.initOptions;

  console.log(selfClass.$navigationItemsContainer.html());
  selfClass.$navigationItemsContainer.children().each(function () {
    // -- each item
    var $t = jQuery(this);



    if(!$t.hasClass('wall-treated')){
      $t.addClass('vgwall-item').addClass('  dzs-layout-item ultibox-item-delegated');
      $t.css({});
      $t.attr('data-suggested-width', o.ultibox_suggestedWidth);
      $t.attr('data-suggested-height', o.ultibox_suggestedHeight);
      $t.attr('data-biggallery', 'vgwall');

      if ($t.attr('data-previewimg')) {
        $t.attr('data-thumb-for-gallery', $t.attr('data-previewimg'));
      } else {

      }


      var uriThumb = $t.attr('data-thumb');
      var thumb_imgblock = null;

      if ($t.find('.layout-builder--item--type-thumbnail').length) {
        thumb_imgblock = $t.find('.layout-builder--item--type-thumbnail');
      }



      if (!uriThumb) {

        if (thumb_imgblock) {
          if (thumb_imgblock.attr('data-imgsrc')) {
          } else {
            if (thumb_imgblock.attr('src')) {
              uriThumb = $t.find('.imgblock').attr('src');
            } else {
              uriThumb = thumb_imgblock.css('background-image');
            }
          }
        }

      }


      if (uriThumb) {

        uriThumb = uriThumb.replace('url(', '');
        uriThumb = uriThumb.replace(')', '');
        uriThumb = uriThumb.replace(/"/g, '');
        $t.attr('data-thumb-for-gallery', uriThumb);
      }
      // -- setup wall
      if (!$t.attr('data-source')) {
        $t.attr('data-source', getDataOrAttr($t, 'data-sourcevp'));
      }
      console.log('$t.attr(\'data-type\') - ', $t.attr('data-type'), $t);
      $t.attr('data-type', 'video');

      if(getDataOrAttr($t, 'data-video-type')){
        $t.attr('data-type', getDataOrAttr($t, 'data-video-type'));
      }

      $t.addClass('wall-treated')
    }



  });


  setTimeout(function () {

    setTimeout(selfClass.handleResize, 1000);
    selfClass.isGalleryLoaded = true;
  }, 1500);

}