'use strict';
import {svgBackButton, svgForwardButton} from './js_dzsvg/_dzsvg_svgs';
import {DzsNavigation} from "./js_dzsvg/navigation/_navigation";
import {defaultPlaylistSettings} from './configs/_playlistSettings';
import {
  assertVideoFromGalleryAutoplayStatus,
  detect_startItemBasedOnQueryAddress,
  navigation_detectClassesForPosition,
  navigation_initScroller, playlist_initialConfig,
  playlist_initSetupInitial,
  playlist_navigation_getPreviewImg,
  playlistGotoItemHistoryChangeForLinks,
  playlistGotoItemHistoryChangeForNonLinks
} from "./js_dzsvg/playlist/_playlistHelpers";
import {
  add_query_arg,
  can_translate,
  convertPluginOptionsToFinalOptions,
  detect_videoTypeAndSourceForElement,
  dzsvgExtraWindowFunctions,
  fullscreen_status,
  get_query_arg,
  init_navigationOuter,
  is_ios,
  is_touch_device,
  setup_videogalleryCategories,
} from "./js_dzsvg/_dzsvg_helpers";
import {loadScriptIfItDoesNotExist} from "./js_common/_dzs_helpers";
import {init_secondCon, secondCon_initFunctions} from "./js_dzsvg/components/_second_con";
import {buildPlaylist} from "./js_dzsvg/playlist/_playlistBuilderFunctions";
import {dzsvg_mode_wall_reinitWallStructure} from "./js_playlist/mode/_mode-wall";
import {dzsvg_playlist_initSearchField} from "./js_playlist/_searchFunctions";
import {
  dzsvg_playlist_setupEmbedAndShareButtons,
} from "./js_playlist/_playlistAuxiliaryButtons";


/**
 * @property {jQuery} _sliderCon
 * @property $feedItemsContainer
 * @property navigation_customStructure
 * @property _mainNavigation
 */
class DzsVideoGallery {
  constructor(argThis, argOptions, $) {


    this.argThis = argThis;
    this.argOptions = argOptions;
    this.$ = $;

    this._sliderCon = null;
    this.$sliderMain = null;
    this.$navigationAndMainArea = null;
    this._mainNavigation = null;
    this.$feedItemsContainer = null;
    this.navigation_customStructure = '';
    this.galleryComputedId = '';
    this.deeplinkGotoItemQueryParam = '';
    this.$galleryButtons = null;
    this.$containerForItems = null;
    this.$navigationItemsContainer = null;
    /** videogallery--navigation-container */
    this.$searchFieldCon = null;
    this.videoAreaWidth = 0;

    this.feed_socialCode = '';

    this.Navigation = null;


    this.isEmbedOpened = false;
    this.isShareOpened = false;
    this.isGalleryLoaded = false; // -- gallery loaded sw, when dimensions are set, will take a while if wall
    this.classInit();
  }

  classInit() {


    var cgallery = null
    ;
    var nrChildren = 0;
    var _navMain
    ;
    //gallery dimensions
    var videoAreaHeight
      , totalWidth
      , totalHeight
      , last_totalWidth = 0 // -- so we can compare to the last values
      , last_totalHeight = 0
      , navWidth = 0 // the _navCon width
      , ww
      , heightWindow
      , last_height_for_videoheight = 0 // -- last responsive_ratio height known
    ;

    var isMenuMovementLocked = false;

    var inter_start_the_transition = null;



    var isMergeSocialIconsIntoOne = false // -- merge all socials into one


    var currNr = -1
      , currNr_curr = -1 // current transitioning
      , nextNr = -1
      , prevNr = -1
      , last_arg = 0
    ;
    var $currVideoPlayer;


    var _rparent
      , ccon
      , heightInitial = -1
    ;
    var conw = 0;

    var wpos = 0
      , hpos = 0
    ;

    var isBusyTransition = false
      , isTransitionStarted = false
      , isBusyAjax = false
    ;
    var isFirstPlayed = false
      , isMouseOver = false
      , isFirstTransition = false // -- first transition made
    ;


    var i = 0;





    var menuitem_width = 0
      , menuitem_height = 0

    var menu_position = 'right';



    var settings_separation_nr_pages = 0;
    var ind_ajaxPage = 0;


    var init_settings = {};


    var action_playlist_end = null;

    var $ = this.$;
    var o = this.argOptions;
    cgallery = $(this.argThis);
    var selfClass = this;



    selfClass.init = init;
    selfClass.cgallery = cgallery;
    selfClass.initOptions = o;


    init_settings = $.extend({}, o);

    playlist_initSetupInitial(selfClass, o);



    menu_position = o.menu_position;


    nrChildren = selfClass.$feedItemsContainer.children('.vplayer,.vplayer-tobe').length;


    if (o.init_on === 'init') {
      init({
        'called_from': 'init'
      });
    }
    if (o.init_on === 'scroll') {
      $(window).on('scroll', handleScroll);
      handleScroll();
    }


    function init(pargs) {


      var margs = {
        caller: null
        , 'called_from': 'default'
      }


      if (selfClass.cgallery.hasClass('dzsvg-inited')) {
        return false;
      }

      if (pargs) {
        margs = $.extend(margs, pargs);
      }

      selfClass.handleResize = handleResize;
      selfClass.handleResize_currVideo = handleResize_currVideo;
      selfClass.apiResponsiveRationResize = apiResponsiveRationResize;

      if (cgallery.parent().parent().parent().hasClass('tab-content')) {
        // -- tabs
        playlist_inDzsTabsHandle(selfClass, margs);
      }

      selfClass.cgallery.addClass('dzsvg-inited');

      ccon = cgallery.parent();
      _rparent = cgallery.parent();


      if (_rparent.parent().hasClass('gallery-is-fullscreen')) {
        if (o.videoplayersettings.responsive_ratio === 'detect') {
          o.videoplayersettings.responsive_ratio = 'default';
        }

      }


      // -- separation - PAGES
      var elimi = 0;


      if (o.settings_separation_mode === 'pages') {

        var dzsvg_page = get_query_arg(window.location.href, 'dzsvgpage');


        if (typeof dzsvg_page == "undefined") {
          dzsvg_page = 1;
        }
        dzsvg_page = parseInt(dzsvg_page, 10);


        if (dzsvg_page === 0 || isNaN(dzsvg_page)) {
          dzsvg_page = 1;
        }

        if (dzsvg_page > 0 && o.settings_separation_pages_number < nrChildren) {

          if (o.settings_separation_pages_number * dzsvg_page <= nrChildren) {
            for (elimi = o.settings_separation_pages_number * dzsvg_page - 1; elimi >= o.settings_separation_pages_number * (dzsvg_page - 1); elimi--) {
              cgallery.children().eq(elimi).addClass('from-pagination-do-not-eliminate');
            }
          } else {
            for (elimi = nrChildren - 1; elimi >= nrChildren - o.settings_separation_pages_number; elimi--) {
              cgallery.children().eq(elimi).addClass('from-pagination-do-not-eliminate');
            }
          }

          cgallery.children().each(function () {
            var _t = $(this);
            if (!_t.hasClass('from-pagination-do-not-eliminate')) {
              _t.remove();
            }
          })

          var str_pagination = '<div class="con-dzsvg-pagination">';
          settings_separation_nr_pages = Math.ceil(nrChildren / o.settings_separation_pages_number);

          nrChildren = cgallery.children().length;

          for (i = 0; i < settings_separation_nr_pages; i++) {
            var str_active = '';
            if ((i + 1) === dzsvg_page) {
              str_active = ' active';
            }
            str_pagination += '<a class="pagination-number ' + str_active + '" href="' + add_query_arg(window.location.href, 'dzsvgpage', (i + 1)) + '">' + (i + 1) + '</a>'
          }

          str_pagination += '</div>';
          cgallery.after(str_pagination);

        }
      }


      if (is_touch_device()) {
        cgallery.addClass('is-touch');
      }



      if (o.settings_mode === 'wall' || o.settings_mode === 'videowall') {
        o.design_shadow = 'off';
      }


      totalWidth = cgallery.width();
      totalHeight = cgallery.height();


      if (isNaN(totalWidth)) {
        totalWidth = 800;
      }

      if (isNaN(totalHeight)) {
        totalHeight = 400;
      }



      playlist_initialConfig(selfClass,o);



      if (can_translate()) {
        $('html').addClass('supports-translate');
      }


      setup_structure();


      if (o.settings_mode === 'normal' || o.settings_mode === 'slider') {
        reinit();
      }




      // -- wall END


      if (o.settings_mode === 'videowall') {


        if (cgallery.parent().hasClass('videogallery-con')) {
          cgallery.parent().css({'width': 'auto', 'height': 'auto'})
        }
        cgallery.css({'width': 'auto', 'height': 'auto'});


      }


      if (o.settings_mode === 'wall' || o.settings_mode === 'videowall' || o.settings_mode === 'rotator' || o.settings_mode === 'rotator3d') {
        reinit({
          'called_from': 'init'
        });
      }



      if (window.dzsvg_settings && window.dzsvg_settings.merge_social_into_one === 'on') {
        isMergeSocialIconsIntoOne = true;
      }

      dzsvg_playlist_setupEmbedAndShareButtons(selfClass, o, isMergeSocialIconsIntoOne);



      if (o.nav_type === 'outer') {
        selfClass.$navigationItemsContainer.addClass(o.nav_type_outer_grid);
        selfClass.$navigationItemsContainer.children().addClass('dzs-layout-item');


        if (o.menuitem_width) {
          o.menuitem_width = '';
        }


        if (o.nav_type_outer_max_height) {
          var nto_mh = Number(o.nav_type_outer_max_height);


          _navMain.css('max-height', nto_mh + 'px');
          _navMain.addClass('scroller-con skin_apple inner-relative');
          selfClass.$navigationItemsContainer.addClass('inner');

          _navMain.css({
            'height': 'auto'
          })

          try_to_init_scroller();
        }
      }


      calculateDims({


        'called_from': 'init'
      });


      if (o.nav_type === 'scroller') {
        _navMain.addClass('scroller-con skin_apple');
        selfClass.$navigationItemsContainer.addClass('inner');

        if ((menu_position === 'right' || menu_position === 'left') && nrChildren > 1) {

          selfClass.$navigationItemsContainer.css({
            'width': menuitem_width
          })
        }
        if ((menu_position === 'bottom' || menu_position === 'top') && nrChildren > 1) {

          selfClass.$navigationItemsContainer.css({
            'height': (menuitem_height)
          })
        }

        _navMain.css({
          'height': '100%'
        })


        // -- try scroller
        if ($.fn.scroller) {
          navigation_initScroller(_navMain);
        } else {
          setTimeout(() => {
            navigation_initScroller(_navMain);
          }, 2000);
        }

        setTimeout(function () {


          if ($('html').eq(0).attr('dir') === 'rtl') {
            _navMain.get(0).fn_scrollx_to(1);
          }
        }, 100);
      }
      // -- scroller END


      // -- NO FUNCTION HIER


      cgallery.on('click', '.rotator-btn-gotoNext,.rotator-btn-gotoPrev', handle_mouse);
      $(document).on('keyup.dzsvgg', handleKeyPress);


      window.addEventListener("orientationchange", handle_orientationchange);
      $(window).on('resize', handleResize);
      handleResize();

      setTimeout(function () {
        calculateDims({

          'called_from': 'first_timeout'
        })
      }, 3000);


      setTimeout(init_playlistIsReady, 100);


      if (o.settings_trigger_resize > 0) {
        setInterval(function () {


          calculateDims({
            'called_from': 'recheck_sizes'
          });
        }, o.settings_trigger_resize);
      }
      ;

      setup_apiFunctions();

      if (o.startItem === 'default') {
        o.startItem = 0;
        if (o.playorder === 'reverse') {
          o.startItem = nrChildren - 1;
        }
      }

      // --- gotoItem
      if (o.settings_mode !== 'wall' && o.settings_mode !== 'videowall') {

        selfClass.isGalleryLoaded = true;


        if (get_query_arg(window.location.href, 'dzsvg_startitem_' + selfClass.galleryComputedId)) {
          o.startItem = Number(get_query_arg(window.location.href, 'dzsvg_startitem_' + selfClass.galleryComputedId));
        }


        var tempStartItem = detect_startItemBasedOnQueryAddress(selfClass.deeplinkGotoItemQueryParam, selfClass.galleryComputedId);
        if (tempStartItem !== null) {
          o.startItem = tempStartItem;
          if (cgallery.parent().parent().parent().hasClass('categories-videogallery')) {
            var _cach = cgallery.parent().parent().parent();

            var ind = _cach.find('.videogallery').index(cgallery);

            if (ind) {
              setTimeout(function () {
                _cach.get(0).api_goto_category(ind, {
                  'called_from': 'deeplink'
                });
              }, 100);
            }
          }
        }

        if (isNaN(o.startItem)) {
          o.startItem = 0;
        }


        if (o.nav_type === 'scroller') {
          // todo: import from _navigation.js
        }


        if (o.settings_go_to_next_after_inactivity) {
          setInterval(function () {
            if (!isFirstPlayed ) {
              gotoNext();
            }
          }, o.settings_go_to_next_after_inactivity * 1000);
        }
      }

      if (o.settings_separation_mode === 'scroll') {
        $(window).bind('scroll', handleScroll);
      }
      if (o.settings_separation_mode === 'button') {
        cgallery.append('<div class="btn_ajax_loadmore">Load More</div>');
        cgallery.on('click', '.btn_ajax_loadmore', click_btn_ajax_loadmore);
        if (o.settings_separation_pages.length === 0) {
          selfClass.cgallery.find('.btn_ajax_loadmore').hide();
        }
      }


      cgallery.on('mouseleave', handleMouseout);
      cgallery.on('mouseover', handleMouseover);

      function call_init_readyForInitingVideos(){
        init_readyForInitingVideos();
      }

      if (o.settings_mode === 'wall'){
        call_init_readyForInitingVideos();
      }else{
        loadScriptIfItDoesNotExist('', 'dzsvp_isLoaded').then(()=>{
            call_init_readyForInitingVideos();
        });
      }
    }

    function init_readyForInitingVideos() {

      // -- first item

      if (selfClass._sliderCon.children().eq(o.startItem).attr('data-type') === 'link') {
        // -- only for link
        gotoItem(o.startItem, {donotopenlink: "on", 'called_from': 'init'});

      } else {
        // -- first item
        // -- normal
        if (o.settings_mode !== 'wall'){
          gotoItem(o.startItem, {'called_from': 'init'});
        }
      }
    }

    function setup_structure() {

      let structNavigationAndMainArea = '<div class="navigation-and-main-area"></div>'


      if (o.design_shadow === 'on') {
        cgallery.prepend('<div class="shadow"></div>');
      }
      selfClass.cgallery.append(structNavigationAndMainArea);
      selfClass.$navigationAndMainArea = selfClass.cgallery.find('.navigation-and-main-area').eq(0);


      var navOptions = {
        navigationType: (o.nav_type === 'thumbs' ? 'hover' : o.nav_type === 'thumbsandarrows' ? 'thumbsAndArrows' : o.nav_type === 'outer' ? 'simple' : o.nav_type),
        menuPosition: o.menu_position,
        menuItemWidth: o.menuitem_width,
        menuItemHeight: o.menuitem_height,
        parentSkin: o.design_skin,
        navigationStructureHtml: selfClass.navigation_customStructure,
      };

      if(o.settings_mode==='wall'){

        navOptions.gridClassItemsContainer = o.navigation_gridClassItemsContainer;
        navOptions.navigationType = 'simple';
        navOptions.filter_structureMenuItemContent = (final_structureMenuItemContent, $currentItemFeed)=>{
          if($currentItemFeed.attr('data-type')){
            final_structureMenuItemContent = final_structureMenuItemContent.replace('dzs-navigation--item"', 'dzs-navigation--item" ' + ' data-video-type="'+$currentItemFeed.attr('data-type')+'"');
          }

          return final_structureMenuItemContent;
        }
      }

      selfClass.Navigation = new DzsNavigation(selfClass, navOptions, $);



      selfClass.$sliderMain = cgallery.find('.sliderMain');
      selfClass._sliderCon = cgallery.find('.sliderCon');

      selfClass._mainNavigation = cgallery.find('.main-navigation');

      selfClass._sliderCon.addClass(o.extra_class_slider_con);

      if (o.settings_mode === 'slider') {
        selfClass.$sliderMain.after(selfClass._mainNavigation);
      }


      if (o.settings_disableVideo === 'on') {
        selfClass.cgallery.addClass('main-area-disabled');
      }


      selfClass.$sliderMain.append('<div class="gallery-buttons"></div>');
      _navMain = selfClass.cgallery.find('.navMain');

      selfClass.$navigationItemsContainer = selfClass.cgallery.find('.videogallery--navigation-container').eq(0);


      if (o.settings_mode === 'slider') {
        _navMain.append('<div class="rotator-btn-gotoNext">' + svgForwardButton + '</div><div class="rotator-btn-gotoPrev">' + svgBackButton + '</div>');
      }
      if (o.settings_mode === 'rotator') {
        _navMain.append('<div class="rotator-btn-gotoNext"></div><div class="rotator-btn-gotoPrev"></div>');
        _navMain.append('<div class="descriptionsCon"></div>');
      }


      selfClass.$galleryButtons = selfClass.$sliderMain.children('.gallery-buttons');


      navigation_detectClassesForPosition(menu_position, selfClass._mainNavigation, cgallery);



      if (o.search_field === 'on') {
        dzsvg_playlist_initSearchField(selfClass, o);
      }

    }


    function setup_apiFunctions() {


      // --- go to video 0 <<<< the start of the gallery
      cgallery.get(0).videoEnd = handleVideoEnd;
      cgallery.get(0).init_settings = init_settings;

      cgallery.get(0).api_play_currVideo = play_currVideo;
      cgallery.get(0).external_handle_stopCurrVideo = video_stopCurrentVideo;
      cgallery.get(0).api_gotoNext = gotoNext;
      cgallery.get(0).api_gotoPrev = gotoPrev;
      cgallery.get(0).api_gotoItem = gotoItem;
      cgallery.get(0).api_responsive_ratio_resize_h = apiResponsiveRationResize;

      // -- ad functions
      cgallery.get(0).api_ad_block_navigation = ad_block_navigation;
      cgallery.get(0).api_ad_unblock_navigation = ad_unblock_navigation;

      cgallery.get(0).api_handleResize = handleResize;
      cgallery.get(0).api_gotoItem = gotoItem;
      cgallery.get(0).api_handleResize_currVideo = handleResize_currVideo;
      cgallery.get(0).api_play_currVideo = play_currVideo;
      cgallery.get(0).api_pause_currVideo = pause_currVideo;
      cgallery.get(0).api_currVideo_refresh_fsbutton = api_currVideo_refresh_fsbutton;
      cgallery.get(0).api_video_ready = galleryTransition;
      cgallery.get(0).api_set_outerNav = function (arg) {
        o.settings_outerNav = arg;
      };
      cgallery.get(0).api_set_secondCon = function (arg) {
        o.settings_secondCon = arg;
      };
      cgallery.get(0).api_set_action_playlist_end = function (arg) {
        action_playlist_end = arg;
      };


      cgallery.get(0).api_played_video = function () {
        isFirstPlayed = true;
      };
    }


    function handleMouseover(e) {


      isMouseOver = true;

    }


    /**
     * handle actions for mouse over
     * @param e
     */
    function handleMouseout(e) {

      isMouseOver = false;

      if (o.nav_type_auto_scroll === 'on') {
        if (o.nav_type === 'thumbs' || o.nav_type === 'scroller') {
          setTimeout(function () {
            if (!isMouseOver) {
              // todo: import from navigation.js
            } else {
              handleMouseout();
            }
          }, 2000);
        }
      }

    }

    function handleKeyPress(e) {


      if (e.type === 'keyup') {

        if (e.keyCode === 27) {
          $('.is_fullscreen').removeClass('is_fullscreen is-fullscreen');
          setTimeout(function () {
            $('.is_fullscreen').removeClass('is_fullscreen is-fullscreen');

          }, 999);
          cgallery.find('.is_fullscreen').removeClass('is_fullscreen is-fullscreen');
          setTimeout(function () {
            calculateDims();
          }, 100);
        }
      }


    }

    function try_to_init_scroller() {

      const baseUrl = window.dzsvg_settings && window.dzsvg_settings.libsUri ? window.dzsvg_settings.libsUri : '';

      loadScriptIfItDoesNotExist(baseUrl + 'dzsscroller/scroller.js', 'dzsscr_init').then(r => {
        window.dzsscr_init(_navMain, {
          'enable_easing': 'on',
          'settings_skin': 'skin_apple'
        });
      });
      $('head').append('<link rel="stylesheet" type="text/css" href="' + baseUrl + 'dzsscroller/scroller.css">');
    }


    function ad_block_navigation() {
      cgallery.addClass('ad-blocked-navigation');
    }

    function ad_unblock_navigation() {
      cgallery.removeClass('ad-blocked-navigation');
    }

    function init_playlistIsReady() {
      init_showPlaylist();


      if (o.settings_secondCon) {
        // -- moving this to bottom
      }


      if (o.settings_outerNav) {

        // -- we moved setup to bottom
      }


      handleResize();

      selfClass.cgallery.addClass('inited');
    }

    function handle_mouse(e) {

      var _t = $(this);
      if (_t.hasClass('rotator-btn-gotoNext')) {

        gotoNext();
      }
      if (_t.hasClass('rotator-btn-gotoPrev')) {

        gotoPrev();
      }
    }


    function init_showPlaylist() {


      cgallery.parent().children('.preloader').removeClass('is-visible');
      cgallery.parent().children('.css-preloader').removeClass('is-visible');


      if (o.init_on === 'scroll' && cgallery.hasClass('transition-slidein')) {
        setTimeout(function () {

          cgallery.addClass('dzsvg-loaded');

          if (cgallery.parent().hasClass('videogallery-con')) {
            cgallery.parent().addClass('dzsvg-loaded');
          }
        }, 300);
      } else {

        cgallery.addClass('dzsvg-loaded');
        if (cgallery.parent().hasClass('videogallery-con')) {
          cgallery.parent().addClass('dzsvg-loaded');
        }
      }
    }

    function setup_navigation_items() {
      if (o.settings_mode === 'normal' || o.settings_mode === 'wall') {
        buildPlaylist(selfClass);
      }
    }

    /**
     * transfer from feed con to slider con
     */
    function setup_transfer_items_to_sliderCon() {


      if (o.settings_mode !== 'wall') {
        var len = selfClass.$feedItemsContainer.find('.vplayer-tobe').length;
        for (i = 0; i < len; i++) {
          var _t = selfClass.$feedItemsContainer.children('.vplayer-tobe').eq(0);
          selfClass._sliderCon.append(_t);
        }
      }
    }

    function reinit(pargs) {
      var margs = {
        caller: null
        , 'called_from': 'default'
      }

      if (pargs) {
        margs = $.extend(margs, pargs);
      }


      selfClass.$containerForItems = cgallery;

      if (selfClass.$feedItemsContainer.children('.vplayer-tobe').length === 0) {
        selfClass.$containerForItems = selfClass._sliderCon;
      }

      setup_navigation_items();
      setup_transfer_items_to_sliderCon();


      if (o.settings_mode === 'videowall') {

        selfClass._sliderCon.children().each(function () {
          // --each item
          var _t = $(this);

          _t.wrap('<div class="dzs-layout-item"></div>');


          o.videoplayersettings.responsive_ratio = 'detect';
          o.videoplayersettings.autoplay = 'off';
          o.videoplayersettings.preload_method = 'metadata';


          o.init_all_players_at_init = 'on';

        });
      }


      if (o.settings_mode === 'rotator3d') {
        menu_position = 'none';

        selfClass._sliderCon.children().each(function () {
          var _t = $(this);
          _t.addClass('rotator3d-item');
          _t.css({'width': selfClass.videoAreaWidth, 'height': videoAreaHeight})
          _t.append('<div class="previewImg" style="background-image:url(' + playlist_navigation_getPreviewImg(_t) + ');"></div>');
          _t.children('.previewImg').bind('click', rotator3d_handleClickOnPreviewImg);

        })
      }


      if (o.init_all_players_at_init === 'on') {

        // -- init all players
        selfClass._sliderCon.find('.vplayer-tobe').each(function () {
          // -- each item
          var _t = $(this);

          o.videoplayersettings.autoplay = 'off';
          o.videoplayersettings.preload_method = 'metadata';


          o.videoplayersettings.gallery_object = cgallery;
          _t.vPlayer(o.videoplayersettings);
        });
      }



      nrChildren = selfClass._sliderCon.children().length;


      if (selfClass.cgallery.find('.feed-dzsvg--socialCode').length) {
        selfClass.feed_socialCode = selfClass.cgallery.find('.feed-dzsvg--socialCode').html();
      }


      if (o.settings_mode === 'wall') {
        dzsvg_mode_wall_reinitWallStructure(selfClass)
      }
      if (o.settings_mode === 'normal') {
        selfClass.$navigationItemsContainer.children('.dzs-navigation--item').unbind('click', handleClickOnNavigationContainer);
        selfClass.$navigationItemsContainer.children('.dzs-navigation--item').bind('click', handleClickOnNavigationContainer);

      }
    }


    /**
     * called from outside
     * @param resizeHeightDimension
     * @param pargs
     * @returns {boolean}
     */
    function apiResponsiveRationResize(resizeHeightDimension, pargs) {



      var margs = {
        caller: null
        , 'called_from': 'default'
      }

      if (pargs) {
        margs = $.extend(margs, pargs);
      }

      if (margs.caller == null || cgallery.parent().hasClass('skin-laptop')) {
        return false;
      }


      if (heightInitial === -1) {
        heightInitial = selfClass.$sliderMain.height();
      }


      $currVideoPlayer.height(resizeHeightDimension);
      selfClass.$sliderMain.css({
        'height': resizeHeightDimension,
        'min-height': resizeHeightDimension
      });


      if (!cgallery.hasClass('ultra-responsive') && (menu_position === 'left' || menu_position === 'right' || menu_position === 'none')) {
        totalHeight = resizeHeightDimension;
        videoAreaHeight = resizeHeightDimension;


        if (o.settings_mode !== 'slider') {
          selfClass._mainNavigation.height(resizeHeightDimension);
        }
        videoAreaHeight = resizeHeightDimension;

        setTimeout(() => {

          selfClass.Navigation.calculateDims({forceMainAreaHeight: resizeHeightDimension});
        })

      } else {
        // -- responsive ratio
        selfClass.cgallery.css('height', 'auto');


        videoAreaHeight = resizeHeightDimension;

      }


      if (margs.caller) {
        margs.caller.data('height_for_videoheight', resizeHeightDimension);
        calculateDims({
          called_from: 'height_for_videoheight'
        });
      }

      if (o.nav_type === 'scroller') {
        setTimeout(function () {


          if (_navMain.get(0) && _navMain.get(0).api_toggle_resize) {
            _navMain.get(0).api_toggle_resize();
          }
        }, 100)
      }


    }


    /**
     * calculate dimensions
     * @param pargs
     * @returns {boolean}
     */
    function calculateDims(pargs) {

      var margs = {
        'called_from': 'default'
      };
      if (pargs) {
        margs = $.extend(margs, pargs);
      }


      totalWidth = selfClass.cgallery.outerWidth();
      totalHeight = selfClass.cgallery.height();

      if (selfClass.cgallery.height() === 0) {
        if (o.forceVideoHeight) {
          if (menu_position === 'top' || menu_position === 'bottom') {
            totalHeight = o.forceVideoHeight + o.design_menuitem_height;
          } else {
            totalHeight = o.forceVideoHeight;
          }
        }
      }


      if (margs.called_from === 'recheck_sizes') {

        if (Math.abs(last_totalWidth - totalWidth) < 4 && Math.abs(last_totalHeight - totalHeight) < 4) {


          return false;
        }

      }


      last_totalWidth = totalWidth;
      last_totalHeight = totalHeight;


      if (totalWidth < 721) {
        cgallery.addClass('under-720');


      } else {
        cgallery.removeClass('under-720');
      }


      if (totalWidth < 601) {
        cgallery.addClass('under-600');
      } else {
        cgallery.removeClass('under-600');
      }


      if (String(cgallery.get(0).style.height).indexOf('%') > -1) {

        totalHeight = cgallery.height();
      } else {

        if (cgallery.data('init-height')) {

          totalHeight = cgallery.data('init-height');
        } else {

          totalHeight = cgallery.height();

          setTimeout(function () {
          })

        }
      }


      selfClass.videoAreaWidth = totalWidth;
      videoAreaHeight = totalHeight;


      menuitem_width = o.menuitem_width;
      menuitem_height = o.menuitem_height;


      if ((menu_position === 'right' || menu_position === 'left') && nrChildren > 1) {
        selfClass.videoAreaWidth -= (menuitem_width);
      }


      if (o.nav_type !== 'outer' && (menu_position === 'bottom' || menu_position === 'top') && nrChildren > 1 && cgallery.get(0).style && cgallery.get(0).style.height && cgallery.get(0).style.height !== 'auto') {
        videoAreaHeight -= (menuitem_height);
      } else {
        videoAreaHeight = o.sliderAreaHeight;
      }


      if ($currVideoPlayer && $currVideoPlayer.data('height_for_videoheight')) {

        videoAreaHeight = $currVideoPlayer.data('height_for_videoheight');

        last_height_for_videoheight = videoAreaHeight;
      } else {
        // -- lets try to get the last value known for responsive ratio if the height of the current video is now currently known
        if (o.videoplayersettings.responsive_ratio && o.videoplayersettings.responsive_ratio === 'detect') {
          if (last_height_for_videoheight) {
            videoAreaHeight = last_height_for_videoheight;
          }

        } else {
          if (menu_position === 'left' || menu_position === 'right') {
            videoAreaHeight = o.sliderAreaHeight;
          }
        }
      }


      if (o.forceVideoHeight !== '' && (!o.videoplayersettings || o.videoplayersettings.responsive_ratio !== 'detect')) {
        videoAreaHeight = o.forceVideoHeight;
      }

      if (o.settings_mode === 'rotator3d') {
        selfClass.videoAreaWidth = totalWidth / 2;
        videoAreaHeight = totalHeight * 0.8;
        menuitem_width = 0;
        menuitem_height = 0;
      }


      cgallery.addClass('transition-' + o.transition_type)


      // === if there is only one video we hide the nav
      if (nrChildren === 1) {
        selfClass._mainNavigation.hide();
      }


      if ($currVideoPlayer) {

      }
      ;

      hpos = 0;
      for (i = 0; i < nrChildren; i++) {

        selfClass._sliderCon.children().eq(i).css({})
        hpos += totalHeight;
      }

      if (o.settings_mode !== 'wall' && o.settings_mode !== 'videowall') {


        selfClass.$sliderMain.css({
          'width': selfClass.videoAreaWidth
        })


        if ((menu_position === 'left' || menu_position === 'right') && nrChildren > 1) {
          selfClass.$sliderMain.css('width', 'auto');

        }

        selfClass.$sliderMain.css({
          'height': videoAreaHeight
        })

      }

      if (o.settings_mode === 'rotator3d') {
        selfClass.$sliderMain.css({
          'width': totalWidth,
          'height': totalHeight
        })
        selfClass._sliderCon.children().css({
          'width': selfClass.videoAreaWidth,
          'height': videoAreaHeight
        })
      }


      // -- END calculate dims for navigation / mode-normal


      if (o.settings_mode === 'normal') {
        hpos = 0;
        wpos = 0;


        selfClass.$navigationItemsContainer.find('.dzs-navigation--item').css({
          'width': menuitem_width,
          'height': menuitem_height
        });

        if (menuitem_height === 0) {
          selfClass.$navigationItemsContainer.find('.dzs-navigation--item').css({
            'height': ''
          });
        }

      }

      if (o.nav_type === 'scroller') {

        if (menu_position === 'top' || menu_position === 'bottom') {
          navWidth = 0;
          selfClass.$navigationItemsContainer.children().each(function () {
            var _t = $(this);
            navWidth += _t.outerWidth(true);
          });
          selfClass.$navigationItemsContainer.width(navWidth);
        }
      }


      calculateDims_secondCon(currNr_curr);


      selfClass.Navigation.calculateDims();
      // -- calculateDims() END
    }

    function handle_orientationchange() {
      setTimeout(function () {
        handleResize();
      }, 1000);
    }

    function handleResize(e, pargs) {
      ww = $(window).width();
      heightWindow = $(window).height();

      conw = _rparent.width();


      if (cgallery.hasClass('try-breakout')) {
        cgallery.css('width', ww + 'px');

        cgallery.css('margin-left', '0');


        if (cgallery.offset().left > 0) {
          cgallery.css('margin-left', '-' + cgallery.offset().left + 'px');
        }
      }


      if (cgallery.hasClass('try-height-as-window-minus-offset')) {

        var aux = heightWindow - cgallery.offset().top;
        if (aux < 300) {
          cgallery.css('height', '90vh')
        } else {
          cgallery.css('height', aux + 'px');
        }

      }


      calculateDims();


      if ($currVideoPlayer) {
        handleResize_currVideo();
      }

    }

    function handleResize_currVideo(e, pargs) {


      var margs = {
        'force_resize_gallery': true
        , 'called_from': 'default'
      };

      if (pargs) {
        margs = $.extend(margs, pargs);
      }


      margs.called_from += '_handleResize_currVideo';

      if (($currVideoPlayer) && $currVideoPlayer.get(0) && ($currVideoPlayer.get(0).api_handleResize)) {


        $currVideoPlayer.get(0).api_handleResize(null, margs);
      }
    }

    function pause_currVideo(e, pargs) {


      var margs = {
        'force_resize_gallery': true
        , 'called_from': 'default'
      };

      if (pargs) {
        margs = $.extend(margs, pargs);
      }


      margs.called_from += '_pause_currVideo';

      if (($currVideoPlayer) && ($currVideoPlayer.get(0).api_pauseMovie)) {


        $currVideoPlayer.get(0).api_pauseMovie(margs);
      }
    }


    function api_currVideo_refresh_fsbutton(argcol) {
      if (typeof ($currVideoPlayer) != "undefined" && typeof ($currVideoPlayer.get(0)) != "undefined" && typeof ($currVideoPlayer.get(0).api_currVideo_refresh_fsbutton) != "undefined") {
        $currVideoPlayer.get(0).api_currVideo_refresh_fsbutton(argcol);
      }
    }




    /**
     *
     * @param {Event} e
     * @returns {boolean}
     */
    function handleHadFirstInteraction(e) {

      if (selfClass.cgallery.data('user-had-first-interaction')) {
        return false;
      }


      selfClass.isHadFirstInteraction = true;
      selfClass.cgallery.data('user-had-first-interaction', 'yes');

      selfClass.cgallery.addClass('user-had-first-interaction');


    }

    function handleClickOnNavigationContainer(e) {
      var _t = $(this);

      var cclass = '';

      if (_t.hasClass('dzs-navigation--item')) {
        cclass = '.dzs-navigation--item';
      }

      if (e) {
        handleHadFirstInteraction(e);
      }


      if (_t.get(0) && _t.get(0).nodeName !== "A") {
        gotoItem(selfClass.$navigationItemsContainer.children(cclass).index(_t));


        if (o.nav_type_auto_scroll === 'on') {
          if (o.nav_type === 'thumbs' || o.nav_type === 'scroller') {


            isMenuMovementLocked = true;

            setTimeout(function () {


              // todo: get form _navigation.js

            }, 100);
            setTimeout(function () {


              isMenuMovementLocked = false;

            }, 2000);
          }
        }

      } else {
        if ($currVideoPlayer && $currVideoPlayer.get(0) && typeof ($currVideoPlayer.get(0).api_pauseMovie) != "undefined") {
          $currVideoPlayer.get(0).api_pauseMovie({
            'called_from': 'handleClickOnNavigationContainer()'
          });
        }

      }

    }

    function handleScroll() {
      if (!selfClass.isGalleryLoaded) {
        // -- try init


        var st = $(window).scrollTop();
        var cthisOffsetTop = cgallery.offset().top;

        var wh = window.innerHeight;


        if (cthisOffsetTop < st + wh + 50) {
          init();
        }

        return;
      } else {
        // -- try LOAD MORE

        var _t = $(this);//==window
        wh = $(window).height();
        if (isBusyAjax === true || ind_ajaxPage >= o.settings_separation_pages.length) {
          return;
        }


        if ((_t.scrollTop() + wh) > (cgallery.offset().top + cgallery.height() - 10)) {
          ajax_load_nextpage();
        }
      }

    }

    function click_btn_ajax_loadmore(e) {

      if (isBusyAjax === true || ind_ajaxPage >= o.settings_separation_pages.length) {
        return;
      }
      cgallery.find('.btn_ajax_loadmore').addClass('disabled')
      ajax_load_nextpage();
    }

    function ajax_load_nextpage() {

      cgallery.parent().children('.preloader').addClass('is-visible');

      $.ajax({
        url: o.settings_separation_pages[ind_ajaxPage],
        success: function (response) {
          setTimeout(function () {

            selfClass.$feedItemsContainer.append(response);
            reinit({
              'called_from': 'ajax_load_nextpage'
            });
            cgallery.find('.btn_ajax_loadmore').removeClass('disabled');
            setTimeout(function () {
              isBusyAjax = false;
              cgallery.parent().children('.preloader').removeClass('is-visible');
              ind_ajaxPage++;


              if (ind_ajaxPage >= o.settings_separation_pages.length) {
                cgallery.children('.btn_ajax_loadmore').removeClass('is-visible');
              }


            }, 1000);
          }, 1000);
        },
        error: function (xhr, ajaxOptions, thrownError) {
          ind_ajaxPage++;
          cgallery.parent().children('.preloader').removeClass('is-visible');

        }
      });

      isBusyAjax = true;
    }

    function gotoItem(arg, pargs) {


      var gotoItemOptions = {

        'ignore_arg_currNr_check': false
        , 'ignore_linking': false // -- does not change the link if set to true
        , donotopenlink: "off"
        , called_from: "default"
      }

      if (pargs) {
        gotoItemOptions = $.extend(gotoItemOptions, pargs);
      }


      if (!(o.settings_mode === 'normal' && o.mode_normal_video_mode === 'one')) {

        if (currNr === arg || isBusyTransition) {
          return false;
        }
      }
      var isTransformed = false; //if the video is already transformed there is no need to wait
      var _currentTargetPlayer = selfClass._sliderCon.children().eq(arg);
      var argsForVideoPlayer = $.extend({}, o.videoplayersettings);


      $currVideoPlayer = _currentTargetPlayer;

      argsForVideoPlayer.gallery_object = cgallery;
      argsForVideoPlayer.gallery_last_curr_nr = currNr;
      if (gotoItemOptions.called_from === 'init') {
        argsForVideoPlayer.first_video_from_gallery = 'on';
      }

      argsForVideoPlayer['gallery_target_index'] = arg;


      var shouldVideoAutoplay = assertVideoFromGalleryAutoplayStatus(currNr, o, cgallery);
      argsForVideoPlayer['autoplay'] = shouldVideoAutoplay ? 'on' : 'off';


      currNr_curr = arg;


      if (o.settings_enable_linking === 'on') {

        if (_currentTargetPlayer.attr('data-type') === 'link' && (gotoItemOptions.donotopenlink !== 'on')) {
          playlistGotoItemHistoryChangeForLinks(ind_ajaxPage, o, cgallery, _currentTargetPlayer, selfClass.deeplinkGotoItemQueryParam);
          return false;
        }
        if (_currentTargetPlayer.attr('data-type') !== 'link') {
          playlistGotoItemHistoryChangeForNonLinks(gotoItemOptions, o, selfClass.galleryComputedId, arg, selfClass.deeplinkGotoItemQueryParam);
        }
      }

      if (o.settings_mode === 'normal' && o.mode_normal_video_mode === 'one') {
        _currentTargetPlayer = selfClass._sliderCon.children().eq(0);
        _currentTargetPlayer.addClass('playlist-mode-video-one--main-player')
        $currVideoPlayer = _currentTargetPlayer;

        var _targetPlayer = selfClass._sliderCon.children().eq(arg);
        var optionsForChange = detect_videoTypeAndSourceForElement(_targetPlayer);
        // -- one
        if ($currVideoPlayer.hasClass('vplayer')) {

          pause_currVideo();


          $currVideoPlayer.get(0).api_change_media(
            optionsForChange.source, {
              'type': optionsForChange.type,
              autoplay: shouldVideoAutoplay ? 'on' : 'off'
            })

        } else {
          // -- one video_mode .. vplayer-tobe
          // -- first item
          $currVideoPlayer.vPlayer(argsForVideoPlayer);
          $currVideoPlayer.addClass('active');
          $currVideoPlayer.addClass('currItem');
        }
        selfClass.$navigationItemsContainer.children('.dzs-navigation--item').removeClass('active');
        selfClass.$navigationItemsContainer.children('.dzs-navigation--item').eq(arg).addClass('active');
      }


      // -- not one
      if (!(o.settings_mode === 'normal' && o.mode_normal_video_mode === 'one')) {
        if (currNr > -1) {
          var _c2 = selfClass._sliderCon.children().eq(currNr);

          // --- if on iPad or iPhone, we disable the video as it had runed in a iframe and it wont pause otherwise
          _c2.addClass('transitioning-out');
          if (o.settings_mode === 'normal' && (is_ios() || _c2.attr('data-type') === 'inline' || (_c2.attr('data-type') === 'youtube' && o.videoplayersettings['settings_youtube_usecustomskin'] !== 'on'))) {
            setTimeout(function () {
              _c2.find('.video-description').remove();
              _c2.data('original-iframe', _c2.html());

              // -- we will delete inline content here
              _c2.html('');

              _c2.removeClass('vplayer');
              _c2.addClass('vplayer-tobe');

            }, 1000);
          }
          ;
        }
      }


      if (o.autoplay_ad === 'on') {

        _currentTargetPlayer.data('adplayed', 'on');
      } else {
        _currentTargetPlayer.data('adplayed', 'off');
      }


      if (_currentTargetPlayer.hasClass('vplayer')) {
        isTransformed = true;
      }


      if (!(o.settings_mode === 'normal' && o.mode_normal_video_mode === 'one')) {
        _currentTargetPlayer.addClass('transitioning-in');
      }


      if (_currentTargetPlayer.hasClass('type-inline') && _currentTargetPlayer.data('original-iframe')) {
        if (_currentTargetPlayer.html() === '') {
          _currentTargetPlayer.html(_currentTargetPlayer.data('original-iframe'));
        }
      }

      // -- not one
      if (!(o.settings_mode === 'normal' && o.mode_normal_video_mode === 'one')) {
        if (_currentTargetPlayer.hasClass('vplayer-tobe')) {
          // -- if not inited

          _currentTargetPlayer.addClass('in-vgallery');
          argsForVideoPlayer['videoWidth'] = selfClass.videoAreaWidth;
          argsForVideoPlayer['videoHeight'] = '';
          argsForVideoPlayer['old_curr_nr'] = currNr;


          if (currNr === -1 && o.cueFirstVideo === 'off') {
            argsForVideoPlayer.cueVideo = 'off';
          } else {
            argsForVideoPlayer.cueVideo = 'on';
          }

          argsForVideoPlayer['settings_disableControls'] = 'off';



          argsForVideoPlayer.htmlContent = '';

          argsForVideoPlayer.gallery_object = cgallery;

          if (argsForVideoPlayer.end_exit_fullscreen === 'off') {
            // -- exit fullscreen on video end

            if (cgallery.find('.vplayer.currItem').hasClass('type-vimeo')) {
              cgallery.find('.vplayer.currItem').removeClass('is_fullscreen is-fullscreen')
            }

            // -- next video has fullscreen status
            if (fullscreen_status() === 1) {
              argsForVideoPlayer.extra_classes = argsForVideoPlayer.extra_classes ? argsForVideoPlayer.extra_classes + ' is_fullscreen is-fullscreen' : ' is_fullscreen is-fullscreen';
            }

            setTimeout(function () {

            }, 500);
          }

          if (o.settings_disableVideo === 'on') {
          } else {
            // -- NOT ONE MODE o.mode_normal_video_mode
            _currentTargetPlayer.vPlayer(argsForVideoPlayer);

          }


        } else {

          // -- NOT (ONE) if already setuped


          if (!(o.init_all_players_at_init === 'on' && currNr === -1)) {
            if (shouldVideoAutoplay) {
              if (typeof _currentTargetPlayer.get(0) != 'undefined' && typeof _currentTargetPlayer.get(0).externalPlayMovie != 'undefined') {
                _currentTargetPlayer.get(0).externalPlayMovie({
                  'called_from': 'autoplayNext'
                });
              }
            }
          }

          if (o.videoplayersettings.end_exit_fullscreen === 'off') {


            if (fullscreen_status() === 1) {
              _currentTargetPlayer.addClass('is_fullscreen is-fullscreen');
            }
          }

          // -- we force a resize on the player just in case it has an responsive ratio


          setTimeout(function () {
            if (typeof _currentTargetPlayer.get(0) != 'undefined' && _currentTargetPlayer.get(0).api_handleResize) {

              _currentTargetPlayer.get(0).api_handleResize(null, {
                force_resize_gallery: true
              })
            }
          }, 250);


        }

      }


      prevNr = arg - 1;
      if (prevNr < 0) {
        prevNr = selfClass._sliderCon.children().length - 1;
      }
      nextNr = arg + 1;
      if (nextNr > selfClass._sliderCon.children().length - 1) {
        nextNr = 0;
      }


      if (o.settings_mode === 'normal') {
        _currentTargetPlayer.css('display', '');
      }
      if (o.settings_mode === 'rotator3d') {
        selfClass._sliderCon.children().removeClass('nextItem currItem hide-preview-img').removeClass('prevItem');
        selfClass._sliderCon.children().eq(nextNr).addClass('nextItem');
        selfClass._sliderCon.children().eq(prevNr).addClass('prevItem');
      }
      if (o.settings_mode === 'rotator') {

        if (currNr > -1) {

        }
        var _descCon = _navMain.children('.descriptionsCon');
        _descCon.children('.currDesc').removeClass('currDesc').addClass('pastDesc');
        _descCon.append('<div class="desc">' + _currentTargetPlayer.find('.menuDescription').html() + '</div>');
        setTimeout(function () {
          _descCon.children('.desc').addClass('currDesc');
        }, 20)

      }


      last_arg = arg;


      if (!(o.settings_mode === 'normal' && o.mode_normal_video_mode === 'one')) {

        if (currNr === -1 || isTransformed) {
          galleryTransition();
          if (o.settings_mode === 'rotator3d') {
            selfClass._sliderCon.children().eq(arg).addClass('hide-preview-img');
          }
        } else {
          cgallery.parent().children('.preloader').addClass('is-visible');

          var delay = 500;

          if (o.settings_mode === 'rotator3d') {
            delay = 10;
            selfClass._sliderCon.children().eq(arg).addClass('currItem');
            setTimeout(function () {

              selfClass._sliderCon.children().eq(arg).addClass('hide-preview-img');
            }, 300);
          }

          inter_start_the_transition = setTimeout(galleryTransition, delay, arg);

        }
      } else {
        isBusyAjax = false;
        isBusyTransition = false;


        currNr = arg;
      }

      calculateDims_secondCon(arg);

      if (o.settings_outerNav) {

        var _c_outerNav = $(o.settings_outerNav);
        _c_outerNav.find('.videogallery--navigation-outer--block ').removeClass('active');
        _c_outerNav.find('.videogallery--navigation-outer--block ').eq(arg).addClass('active');

        _c_outerNav.find('*[data-global-responsive-ratio]').each(function () {
          var _t4 = $(this);

          var rat = Number(_t4.attr('data-global-responsive-ratio'));

          _t4.height(rat * _t4.width());
        })
      }

      if (cgallery.hasClass('responsive-ratio-smooth')) {
        if (!_currentTargetPlayer.attr('data-responsive_ratio')) {
          apiResponsiveRationResize(heightInitial);
        } else {
          $(window).trigger('resize');
        }

      }


      cgallery.removeClass('hide-players-not-visible-on-screen');
      setTimeout(function () {

        cgallery.addClass('hide-players-not-visible-on-screen');
        selfClass._sliderCon.find('.transitioning-in').removeClass('transitioning-in');
        selfClass._sliderCon.find('.transitioning-out').removeClass('transitioning-out');


        var _extraBtns = null;


        if (cgallery.parent().parent().next().hasClass('extra-btns-con')) {
          _extraBtns = cgallery.parent().parent().next();
        }
        if (cgallery.parent().parent().next().next().hasClass('extra-btns-con')) {
          _extraBtns = cgallery.parent().parent().next().next();
        }
        if (_extraBtns) {
          _extraBtns.find('.stats-btn').attr('data-playerid', $currVideoPlayer.attr('data-player-id'));

        }
      }, 400);

      isBusyTransition = true;


      return !(o.settings_mode === 'normal' && o.mode_normal_video_mode === 'one');



    }


    function galleryTransition() {
      if (isTransitionStarted) {
        return;
      }

      var arg = last_arg;


      var _c = selfClass._sliderCon.children().eq(arg);

      isTransitionStarted = true;
      clearTimeout(inter_start_the_transition);
      cgallery.parent().children('.preloader').removeClass('is-visible');


      selfClass._sliderCon.children().removeClass('currItem');

      if (currNr === -1) {
        _c.addClass('currItem');
        _c.addClass('no-transition');
        setTimeout(function () {
          selfClass._sliderCon.children().eq(currNr).removeClass('no-transition')
        })
      } else {

        if (currNr !== arg) {

          selfClass._sliderCon.children().eq(currNr).addClass('transition-slideup-gotoTop')
        } else {

          selfClass._sliderCon.children().eq(currNr).addClass('currItem');
        }


      }

      setTimeout(setCurrVideoClass, 100);
      selfClass.$navigationItemsContainer.children('.dzs-navigation--item').removeClass('active');
      selfClass.$navigationItemsContainer.children('.dzs-navigation--item').eq(arg).addClass('active');

      if (o.nav_type === 'thumbs' || o.nav_type === 'scroller' || o.nav_type === 'thumbsandarrows') {

        selfClass.$navigationItemsContainer.children('.dzs-navigation--item').removeClass('active');
        selfClass.$navigationItemsContainer.children('.dzs-navigation--item').eq(arg).addClass('active');
      }


      setTimeout(function () {
        $('window').trigger('resize');
        selfClass._sliderCon.children().removeClass('transition-slideup-gotoTop');
      }, 1000);

      if (is_ios() && currNr > -1) {
        if (selfClass._sliderCon.children().eq(currNr).children().eq(0).length > 0 && selfClass._sliderCon.children().eq(currNr).children().eq(0)[0] !== undefined) {
          if (selfClass._sliderCon.children().eq(currNr).children().eq(0)[0].tagName === 'VIDEO') {
            selfClass._sliderCon.children().eq(currNr).children().eq(0).get(0).pause();
          }
        }
      }

      if (isFirstTransition) {

        video_stopCurrentVideo({
          'called_from': 'the_transition'
        });
      }

      if (currNr > -1) {


        isFirstTransition = true;


      }
      currNr = arg;

      setTimeout(function () {

        isBusyTransition = false;
        isTransitionStarted = false;
        hideAllVideosButCurrentVideo();
      }, 400);
    }

    // -- end the_transition()


    function calculateDims_secondCon(arg) {


      if (o.settings_secondCon) {

        var _c = $(o.settings_secondCon);


        _c.find('.item').removeClass('active');
        _c.find('.item').eq(arg).addClass('active');
        _c.find('.dzsas-second-con--clip').css({
            'height': _c.find('.item').eq(arg).outerHeight(false),
          'left': -(arg * 100) + '%'
          }
        );


      }
    }

    function hideAllVideosButCurrentVideo() {
      if (o.settings_mode === 'normal') {

        selfClass._sliderCon.children().each(function () {
          var _t = $(this);

          if (!_t.hasClass('currItem') ) {
            _t.hide();
          }
        })
      }
    }


    function setCurrVideoClass() {

      if ($currVideoPlayer) {

        $currVideoPlayer.addClass('currItem');
      }
    }


    function play_currVideo() {

      if (selfClass._sliderCon.children().eq(currNr).get(0) && selfClass._sliderCon.children().eq(currNr).get(0).externalPauseMovie) {
        selfClass._sliderCon.children().eq(currNr).get(0).api_playMovie({
          'called_from': 'api_playMovie'
        });
      }
    }

    function video_stopCurrentVideo(pargs) {

      var margs = {
        'called_from': 'default'
      }

      if (pargs) {
        margs = $.extend(margs, pargs);
      }

      if (!(is_ios()) && currNr > -1 && o.mode_normal_video_mode !== 'one') {
        if (selfClass._sliderCon.children().eq(currNr).get(0) && selfClass._sliderCon.children().eq(currNr).get(0).externalPauseMovie) {
          selfClass._sliderCon.children().eq(currNr).get(0).externalPauseMovie({
            'called_from': 'external_handle_stopCurrVideo() - ' + margs.called_from
          });
        }
      }
    }




    function gotoPrev() {

      if (o.playorder === 'reverse') {
        gotoNext();
        return;
      }

      var tempNr = currNr - 1;
      if (tempNr < 0) {
        tempNr = selfClass._sliderCon.children().length - 1;
      }
      gotoItem(tempNr);


    }

    function gotoNext() {

      if (o.playorder === 'reverse') {
        gotoPrev();
        return;
      }

      var isGoingToGoNext = true;
      var tempNr = currNr + 1;
      if (tempNr >= selfClass._sliderCon.children().length) {
        tempNr = 0;


        if (o.loop_playlist !== 'on') {
          isGoingToGoNext = false;
        }

        if (action_playlist_end) {
          action_playlist_end(cgallery);
        }
      }


      if (isGoingToGoNext) {

        // -- we will go forward with next movie
        gotoItem(tempNr);
      }


      if (o.nav_type_auto_scroll === 'on') {
        if (o.nav_type === 'thumbs' || o.nav_type === 'scroller') {
          setTimeout(function () {
            selfClass.Navigation.animate_to_curr_thumb();
          }, 20);
        }
      }
    }

    function handleVideoEnd() {
      // -- cgallery
      if (o.autoplayNext === 'on') {
        gotoNext();
      }
    }


    function rotator3d_handleClickOnPreviewImg(e) {
      var _t = $(this);
      var selectedIndex = _t.parent().parent().children().index(_t.parent());

      if (e) {
        handleHadFirstInteraction(e);
      }

      gotoItem(selectedIndex);
    }

  }
}


export function apply_videogallery_plugin($) {
  $.fn.vGallery = function (argOptions) {

    var finalOptions = {};
    var defaultOptions = Object.assign({}, defaultPlaylistSettings);
    finalOptions = convertPluginOptionsToFinalOptions(this, defaultOptions, argOptions);
    this.each(function () {

      return new DzsVideoGallery(this, finalOptions, $);
    }); // end each

  }


  window.dzsvg_init = function (selector, settings) {
    if (typeof (settings) != "undefined" && typeof (settings.init_each) != "undefined" && settings.init_each === true) {
      if (Object.keys(settings).length === 1) {
        settings = undefined;
      }
      $(selector).each(function () {
        var _t = $(this);
        _t.vGallery(settings)
      });
    } else {
      $(selector).vGallery(settings);
    }
  };
  // -- deprecated
  window.zsvg_init = function (selector, settings) {
    $(selector).vGallery(settings);
  };
}


if (!window.dzsvg_settings) {
  if (window.dzsvg_default_settings) {
    window.dzsvg_settings = {...{}};
  }
}
window.setup_videogalleryCategories = setup_videogalleryCategories;


function dzsvg_handleInitedjQuery(){


  (function ($) {
    apply_videogallery_plugin(jQuery);
  })(jQuery);





  jQuery(document).ready(function ($) {
    dzsvg_init('.videogallery.auto-init', {init_each: true});
    secondCon_initFunctions();
    init_navigationOuter();
    init_secondCon();
  })
  dzsvgExtraWindowFunctions();
}

loadScriptIfItDoesNotExist('', 'jQuery').then(()=>{
  dzsvg_handleInitedjQuery();
})